@import "Colors.scss";
@import "Fonts.scss";

// .title {
//     font-family: 'Metropolis', sans-serif;
//     color: white;
//     max-width: 800px;
//     margin: 0 auto;
//     padding: 40px;
// }
.privacy-policy-wrapper {


    padding-top: 100px;

    .privacy-policy {
        font-family: "Metropolis", sans-serif;
        color: white;
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        line-height: 1.6;

        h1,
        h2,
        h3,
        p {
            margin-bottom: 10px;
        }

        .privacy-policy-title {
            font-weight: bold;
            font-size: 2em; // Adjust as needed
            text-align: center;
            margin-bottom: 20px;
        }

        .last-updated {
            font-weight: normal;
            font-style: italic;
            text-align: center;
            font-size: 1em; // Adjust as needed
            margin-bottom: 20px;
        }

        .section-title {
            font-weight: 600; // SemiBold
            font-size: 1.5em; // Adjust as needed
            margin-top: 20px;
        }

        .subsection-title {
            font-weight: 500; // Medium
            font-size: 1.2em; // Adjust as needed
            margin-top: 15px;
        }

        .section {
            background-color: rgba(255, 255, 255, 0.1); // Light white background for readability
            padding: 10px;
            border-radius: 5px;
            margin-top: 10px;

            p {
                font-weight: normal;
                font-size: 1em; // Adjust as needed
            }
        }
    }
}
